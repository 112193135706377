<template>
    <div class="w-100 h-100 d-flex justify-center align-center">
        <v-progress-circular indeterminate :size="118" :width="8"></v-progress-circular>
    </div>
</template>

<script>
import api from '@/services/api'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const checkoutSessionId = route.query.chksessid
        if (!checkoutSessionId) {
            router.push({name: 'settings'})
            return
        }

        const checkoutStatus = ref(null)
        
        const getCheckoutData = async () => {         
            const response = await api.post('/api/billing/checkout-status', {
                session_id: checkoutSessionId
            })

            if (response.status !== 'complete') {
                await router.push({name: 'settings'})
                return
            }

            checkSubscription()
            
        }

        getCheckoutData()

        const checkSubscription = async () => {
            const response = await api.get('/api/billing/subscription')

            if (response.subscription) {
                router.push({name: 'dashboard'})
                return true
            }

            setTimeout(checkSubscription, 2500)
        }

        return {}
    }
}
</script>