<template>
    <div v-if="user" class="d-flex flex-column align-center mb-2">
        <v-avatar :image="user.picture" size="80"></v-avatar>
        <p class="text-subtitle-2">{{ user.name }}</p>
        <p class="text-caption">{{ user.email }}</p>
    </div>
    <v-divider />
    <v-list nav density="compact" class="text-left">
        <v-list-item :prepend-icon="mdiViewDashboard" title="Dashboard" value="dashboard" :to="{name: 'dashboard'}" />
        <v-list-item :prepend-icon="mdiCogs" title="Settings" value="settings" :to="{name: 'settings'}" />
    </v-list>
    
</template>

<script>
import { mdiViewDashboard, mdiCogs } from '@mdi/js'

export default {
    props: ['user'],
    setup() {
        return {mdiViewDashboard, mdiCogs}
    }
}
</script>