<template>
    <div class="w-100 h-100 d-flex align-center justify-center">
        <v-btn color="grey-darken-3" size="x-large" @click="handleLogin">Login via Google</v-btn>
    </div>
</template>

<script>
import api from '@/services/api'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
export default {
    setup() {
        const userStore = useUserStore()
        const router = useRouter()
        if (userStore.user) {
            router.push({
                name: 'dashboard'
            })
        }

        const handleLogin = async () => {
            const response = await api.get('/api/gauth/login')
            if(response.redirect) {
                window.location.href = response.redirect
            }
        }

        return {handleLogin}
    }
}
</script>