<template>
    <v-sheet>
        <v-btn @click="goBack">Go back</v-btn>
        <div class="w-100 mt-3">
            <div v-if="!subscription">
                <SubscriptionCheckoutForm />
            </div>
        </div>
    </v-sheet>
</template>

<script>
import api from '@/services/api'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import SubscriptionCheckoutForm from './SubscriptionCheckoutForm.vue'
import SubscriptionView from './SubscriptionView.vue'
export default {
    components: {SubscriptionCheckoutForm, SubscriptionView},
    setup() {
        const subscription = ref(null)
        const router = useRouter()

        const goBack = () => {
            router.go(-1)
        }

        const loadSubscription = async () => {
            const response = await api.get('/api/billing/subscription')
            
            if(response.subscription) {
                router.push({name: 'settings'})
            }
        }

        loadSubscription()

        return {subscription, loadSubscription, goBack}
    }
}
</script>