<template>
    <p class="text-h5">Settings</p>
    <div class="mt-3">
        <SubscriptionView />
    </div>
</template>

<script>
import SubscriptionView from '@/components/settings/SubscriptionView.vue'
export default {
    components: {SubscriptionView},
    setup() {

    }
}
</script>