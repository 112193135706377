<template>
    <v-sheet>
        <h5 class="text-h5 mb-3">Dashboard</h5>
        <Extension />
        <v-card class="mb-5">
            <v-card-title>Your current plan: 
                <v-chip class="text-uppercase font-weight-bold" variant="flat" :color="userStore.subscriptionPlanTitle === 'standard' ? 'success' : 'warning'">
                    {{ userStore.subscriptionPlanTitle }}
                </v-chip>
            </v-card-title>
            <div class="mt-5 mb-10 text-center ">
                <p class="text-h1">{{ userStore.actions }}</p>
                <p class="text-caption text-medium-emphasis">available actions</p>
                <div v-if="userStore.user.subscription">
                    <p class="text-caption text-medium-emphasis">Until: {{ formatDate(userStore.user.subscription.current_period_end) }}</p>
                    <v-btn class="mt-3" color="success" :to="{name: 'checkout-actions'}">Add more actions</v-btn>
                </div>
                <div v-else>
                    <v-btn class="mt-3" color="success" :to="{name: 'settings'}">Upgrade to standard plan</v-btn>
                </div>
            </div>
            
        </v-card>

        <Devices />
    </v-sheet>
</template>

<script>
import {useUserStore} from '@/stores/userStore'
import { storeToRefs } from 'pinia'
import Devices from '@/components/Devices'
import Extension from '@/components/Extension'
export default {
    components: {Devices, Extension},
    setup() {
        const userStore = useUserStore()
        userStore.reloadUser()
        const {actions} = storeToRefs(userStore)

        const formatDate = (timestamp) => {
            const date = new Date(timestamp * 1000)
            return date.toDateString()
        }

        

        return {actions, userStore, formatDate}
    }
}
</script>