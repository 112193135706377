import { defineStore } from 'pinia'
import api from '@/services/api'

export const useUserStore = defineStore('userStore', {
    state: () => ({
        user: null,
        initialized: false
    }),
    getters: {
        actions: (state) => {
            return state.user?.actions ?? null
        },
        userId: (state) => {
            return state.user?.id ?? null
        },
        subscriptionPlanTitle: (state) => {
            return state.user.subscription_status === 1 ? 'standard' : 'free'
        }
    },
    actions: {
        async loadUser() {
            const response = await api.get('/api/gauth/whoami')
            this.user = response.user
            this.initialized = true
        },
        async reloadUser() {
            const response = await api.get('/api/gauth/whoami')
            this.user = response.user
        },
        dropUser() {
            this.user = null
        }
    }
})