import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import DashboardView from '@/views/DashboardView.vue'
import SettingsView from '@/views/SettingsView.vue'
import CheckoutSession from '@/views/Settings/CheckoutSession.vue'
import CheckoutSubscription from '@/components/settings/CheckoutSubscription.vue'
import ActionsCheckoutForm from '@/components/settings/ActionsCheckoutForm.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {auth: false}
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {auth: true}
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {auth: true}
  },
  {
    path: '/checkout/subscribtion',
    name: 'checkout-subscription',
    component: CheckoutSubscription,
    meta: {auth: true}
  },
  {
    path: '/checkout/actions',
    name: 'checkout-actions',
    component: ActionsCheckoutForm,
    meta: {auth: true}
  },
  {
    path: '/settings/checkout-session',
    name: 'settings-checkout-session',
    component: CheckoutSession,
    meta: {auth: true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
