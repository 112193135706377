<template>
    <div class="d-flex justify-center w-100">
        <div class="checkout-wrappe w-100">
            <div id="checkout-form">

            </div>
        </div>
    </div>
    
</template>

<script>
import api from '@/services/api'
import { onUnmounted } from 'vue'
export default {
    setup() {

        let stripe = null
        let checkout = null
        const initialize = async () => {
            const response = await api.post('/api/billing/checkout-session')
            if (response.error) {
                console.log('something went wrong')
                return
            }

            stripe = Stripe(response.clientPublic)
            checkout = await stripe.initEmbeddedCheckout({
                clientSecret: response.clientSecret
            })

            checkout.mount('#checkout-form');
        }

        initialize()

        onUnmounted(() => {
            if (checkout) {
                checkout.destroy()
            }
        })

        return {}
    },
}
</script>

<style scoped>
.checkout-wrapper {
    max-width: 600px;
}
</style>