<template>
    <v-card>
        <v-card-title>
            <div class="d-flex justify-space-between">
                <span>Your devices ({{ currentCount }}/{{ maxCount }})</span>
                <v-btn @click="handleAdd" color="grey-darken-3">Add New</v-btn>
            </div>
        </v-card-title>
        <v-card-text>
            <v-list lines="two">
                <v-list-item 
                    v-for="(device, index) in devicesList" 
                    :key="device.id" 
                    :title="device.useragent" 
                    :subtitle="device.ts_created"
                    :variant="index % 2 ? 'tonal' : 'flat'"
                >
                    <template v-slot:append>
                        <v-btn @click="handleDelete(device.id)" :icon="mdiDelete"></v-btn>
                    </template>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>

    <v-dialog
      v-model="code"
      width="auto"
      persistent
    >
        <v-card class="d-flex align-center justify-space-between flex-column code-dialog text-center">
            <p></p>
            <div>
                <p class="text-subtitle-1">Your new device code is</p>
                <p @click="handleCopy" class="my-3 text-h2 font-weight-black cursor-pointer">{{ code }}</p>
                <p class="text-subtitle-2">{{ copyMessage }}</p>
            </div>
            <div>
                <v-btn @click="handleCloseCode" color="grey-darken-3">ok</v-btn>
            </div>
        </v-card>
    </v-dialog>
    <v-snackbar
      v-model="error"
      timeout="5000"
    >
      {{ errorMessage }}
    </v-snackbar>
</template>

<script>
import api from '@/services/api'
import { ref } from 'vue'
import {mdiDelete} from '@mdi/js'
export default {
    setup() {
        const code = ref(null)
        const copyMessage = ref('click on code to copy it to the clipboard')
        const error = ref(false)
        const errorMessage = ref(null)

        const maxCount = 5

        const currentCount = ref(0)
        const handleAdd = async () => {
            copyMessage.value = 'click on code to copy it to the clipboard'
            const response = await api.post('/api/device/code')
            if (response.error) {
                error.value = true
                errorMessage.value = response.error

                return
            }

            code.value = response.code
        }

        const devicesList = ref([])

        const loadDevices = async () => {
            const response = await api.get('/api/device/list')
            devicesList.value = response
            currentCount.value = response.length
        }

        const handleDelete = async (deviceId) => {
            const response = await api.post('/api/device/delete', {
                device_id: deviceId
            })

            if (response.success) {
                loadDevices()
            }
        }

        loadDevices()

        const handleCopy = () => {
            navigator.clipboard.writeText(code.value)
            copyMessage.value = 'The code has been successfully copied'
        }

        const handleCloseCode = () => {
            code.value = null
        }

        return {handleAdd, code, handleCopy, copyMessage, handleCloseCode, devicesList, mdiDelete, handleDelete, maxCount, currentCount}
    }
}
</script>

<style scoped>
.code-dialog {
    min-width: 300px;
    min-height: 300px;
}
</style>