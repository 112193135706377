<template>
    <div v-if="!extensionInstalled" class="mb-5">
        <v-alert type="warning">
            Looks like you don't have our Google Chrome extension! Let's install it and reload this page!
            <v-btn @click="goToStore" class="float-right" size="small" color="deep-purple-accent-4">Install from Google Store</v-btn>
        </v-alert>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const extensionInstalled = ref(true)

        const checkExtension = async () => {
            if (!chrome.runtime) {
                extensionInstalled.value = false
                return
            }
            chrome.runtime.sendMessage(process.env.VUE_APP_EXTENSION_ID, { message: "version" },
                function (reply) {
                    if (reply) {
                        if (reply.version) {
                            extensionInstalled.value = true
                        }
                    }
                    else {
                        extensionInstalled.value = false
                    }
                }
            )
        }

        const goToStore = () => {
            window.open(process.env.VUE_APP_EXTENSION_LINK,'_blank')
        }

        checkExtension()

        return {extensionInstalled, goToStore}
    }
}
</script>