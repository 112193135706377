<template>
  <v-app v-if="userStore.initialized">
    <v-layout>
      <v-app-bar elevation="1" title="TextCraft">
        <template v-slot:prepend>
          <v-app-bar-nav-icon v-if="userStore.user" @click="drawer = !drawer"></v-app-bar-nav-icon>
        </template>
      </v-app-bar>
      
      <v-navigation-drawer v-if="userStore.user" v-model="drawer" mobile-breakpoint="md">
        <Sidebar :user="userStore.user" />

        <template v-slot:append>
          <div class="pa-2">
            <v-btn @click="handleLogout" color="grey-darken-3" block>
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      

      <v-main class="ma-5" style="min-height: 300px;">
        <router-view/>
      </v-main>
    </v-layout>
  </v-app>
  
</template>

<script>
import { ref } from 'vue'
import api from '@/services/api.js'
import Sidebar from '@/components/Sidebar'
import { useUserStore } from '@/stores/userStore'
import { useRoute, useRouter } from 'vue-router'
import { watch } from 'vue'
import { storeToRefs } from 'pinia'

export default {
  components: {Sidebar},
  setup() {
    const drawer = ref(null)

    const userStore = useUserStore()
    const router = useRouter()
    const route = useRoute()

    const {userId} = storeToRefs(userStore)

    const initApp = async () => {
      await userStore.loadUser()
      checkAuthRoute()
    }

    watch(userId, (newVal) => {
      checkAuthRoute()
    })

    watch(route, (to) => {
      if(userStore.initialized) {
        checkAuthRoute()
      }
    })

    const checkAuthRoute = () => {
      if (!userStore.user && route.meta.auth) {
        
        router.push({name: 'login'})
      }
    }

    const handleLogout = async () => {
      const response = await api.post('/api/gauth/logout')
      userStore.dropUser()
    }

    initApp()

    return {drawer, userStore, handleLogout}
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
