<template>
    <p class="text-h6 mb-5">Subscription</p>
    <div class="w-100 d-flex align-center justify-center">
        <div class="subscription-card-wrapper">
            <v-card elevation="5">
                <v-card-title class="mt-1 mb-5">
                    <v-chip class="text-h4 font-weight-bold" variant="text">Standard
                        
                    </v-chip>
                    <v-badge
                        v-if="subscription"
                        color="success"
                        content="active"
                        inline
                    >
                    </v-badge>
                    
                </v-card-title>
                <v-card-text class="text-center">
                    <div class="ml-3">
                        <p class="text-subtitle-1"><span class="text-h2 font-weight-bold">15€</span> per month</p>
                        <p class="text-h3 mt-3">1000 actions</p>
                    </div>
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <div v-if="subscription" class="my-5">
                        <p class="text-h6">
                            <span v-if="subscription.cancel_at_period_end">Until: </span>
                            <span v-else>Next billing date: </span>
                            <span class="font-weight-bold">{{ formatDate(subscription.current_period_end) }}</span>
                        </p>
                        <div class="mt-5">
                            <v-btn v-if="!subscription.cancel_at_period_end" color="error" @click="cancelSubscription">Cancel subscription</v-btn>
                            <v-btn v-if="subscription.cancel_at_period_end" color="warning" @click="resumeSubscription">Resume subscription</v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <v-btn color="success" :to="{name: 'checkout-subscription'}">Subscribe</v-btn>
                    </div>
                </v-card-text>
            </v-card>
            <div v-if="subscription" class="text-center mt-5">
                <v-btn color="success" :to="{name: 'checkout-actions'}">add more actions</v-btn>
            </div>
        </div>

    </div>
    
</template>

<script>
import api from '@/services/api'
import { ref } from 'vue'

export default {
    setup() {
        const formatDate = (timestamp) => {
            const date = new Date(timestamp * 1000)
            return date.toDateString()
        }

        const subscription = ref(null)

        const loadSubscription = async () => {
            const response = await api.get('/api/billing/subscription')
            subscription.value = response.subscription
        }

        loadSubscription()

        const cancelSubscription = async () => {
            const response = await api.post('/api/billing/cancel-subscription')
            
            loadSubscription()
        }

        const resumeSubscription = async () => {
            const response = await api.post('/api/billing/resume-subscription')
            
            loadSubscription()
        }

        return {formatDate, cancelSubscription, resumeSubscription, subscription}
    }
}
</script>

<style scoped>
.subscription-card-wrapper {
    min-width: 400px;
}
</style>